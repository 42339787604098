<template>
    <div>
        <h3 class="text-center">SURAT PERSETUJUAN RETUR</h3>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Kepada Yth
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.form.supplier_name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    No. SPR
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.form.code }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Dari
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.form.company ? this.form.company.Name : '' }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Tanggal
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ formatDate(this.form.date) }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Nama
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.form.company ? this.form.company.Name : '' }} (Cabang {{ this.form.warehouse ? this.form.warehouse.name : '' }})
                </td>
                <td class="td vs-table--td" style="padding: 5px"></td>
                <td class="td vs-table--td" style="padding: 5px"></td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Alamat
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ `${this.form.warehouse ? this.form.warehouse.address : ''}` }}
                </td>
                <td class="td vs-table--td" style="padding: 5px"></td>
                <td class="td vs-table--td" style="padding: 5px"></td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    No. Telepon
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.form.warehouse ? this.form.warehouse.phone : '' }}
                </td>
                <td class="td vs-table--td" style="padding: 5px"></td>
                <td class="td vs-table--td" style="padding: 5px"></td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    No. NPWP
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    : {{ this.form.company ? this.form.company.Npwp : '' }}
                </td>
                <td class="td vs-table--td" style="padding: 5px"></td>
                <td class="td vs-table--td" style="padding: 5px"></td>
            </tr>
        </table>

        <table v-if="this.form.lines" width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th rowspan="2" class="text-center">No.</th>
                    <th rowspan="2" class="text-center">Nama Produk</th>
                    <th rowspan="2" class="text-center">Kode Produk</th>
                    <th rowspan="2" class="text-center">Batch</th>
                    <th rowspan="2" class="text-center">Total</th>
                    <th rowspan="2" class="text-center">Keterangan Kondisi Produk</th>
                    <th colspan="2" class="text-center">Eksekusi</th>
                    <th rowspan="2" class="text-center">Note</th>
                </tr>
                <tr>
                    <th>Pemusnahan</th>
                    <th>Retur</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in this.form.lines" :key="index" style="padding-bottom: 5px">
                    <td>{{ index+1 }}</td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.item_name ? item.item_name : (item.item ? item.item.name : '') }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.sku_code ? item.sku_code : (item.item ? item.item.sku_code : '') }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.batch }}
                    </td>
                    <td class="td vs-table--td text-right" style="padding: 5px">
                        {{ item.quantity }} {{ item.unit ? item.unit : (item.item_unit ? item.item_unit.name : '') }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        Expired / Kemasan Rusak
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <table width="50%" class="vs-table vs-table--tbody-table w-1/2 print">
            <thead>
                <tr>
                    <th class="text-center">Diajukan Oleh</th>
                    <th class="text-center" colspan="2">Diketahui Oleh</th>
                    <th class="text-center">Disetujui Oleh</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        BLC/OS
                        <br/>
                        <br/>
                        <br/>
                        (.........................)
                    </td>
                    <td class="text-center">
                        BAM/BAS
                        <br/>
                        <br/>
                        <br/>
                        (.........................)
                    </td>
                    <td class="text-center">
                        BM/OM
                        <br/>
                        <br/>
                        <br/>
                        (.........................)
                    </td>
                    <td class="text-center">
                        Principal
                        <br/>
                        <br/>
                        <br/>
                        (.........................)
                    </td>
                </tr>
                <tr>
                    <td>Tanggal:</td>
                    <td>Tanggal:</td>
                    <td>Tanggal:</td>
                    <td>Tanggal:</td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button class="mr-2" color="primary" @click="handlePrint()">Print</vs-button>
            <vs-button class="mr-2" color="danger" type="border" @click="handleClose()">Close</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    created() {
        this.id = this.$route.params.id;
        this.po = this.$route.params.po;
        this.print = this.$route.query.print;
        this.initData();
    },
    data() {
        return {
            id: null,
            po: null,
            renderForm: true,
            popShow: false,
            returnType: [
                {
                    value: true,
                    name: "With reference",
                },
                {
                    value: false,
                    name: "Without reference",
                }
            ],
            selectedReturnType:
            {
                value: true,
                name: "With reference",
            },
            attachment: null,
            line: { id: 0, item_unit: null, quantity: 0, item: null },
            form: {
                id: 0,
                purchase_order_id: 0,
                purchase_order: null,
                company: null,
                supplier_id: 0,
                supplier: null,
                tax: null,
                warehouse: null,
                description: "",
                code: "",
                note: "",
                date: moment().toDate(),
                status: "",
                status_outbound: "",
                type: "",
                dn_number: "",
                source: "",
                posting_date: moment().toDate(),
                grr_code: "",
                sr_ref: "",
                status_reversal: "",
                posting_date_reversal: moment().toDate(),
                lines: [{ ...this.line }],
            },
        }
    },
    methods: {
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: `${this.$store.state.purchase.poReturn.baseRouterName}.index`,
            });
            this.$vs.loading.close();
        },
        initData() {
            if (this.id) {
                const withPO = this.po && this.po > 0
                const url = withPO ? `${this.$store.state.purchase.poReturn.baseUrlPath}/${this.id}` : `${this.$store.state.purchase.supplierReturn.baseUrlPath}/${this.id}`;
                this.selectedReturnType = withPO ? this.returnType[0] : this.returnType[1]
                this.$vs.loading();
                this.$http.get(url).then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        this.form = resp.data;
                        this.form.company = resp.data.purchase_order.company
                        this.renderForm = false;
                        this.$nextTick(() => {
                            this.renderForm = true;
                        })

                        if (this.print) {
                            setTimeout(() => {
                                this.handlePrint()
                            }, 1600);
                        }
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: `${resp.code} : ${resp.message}. Do you want to refetch the data?`,
                            accept: this.initData,
                        });
                    }
                });
            }
        },
        async handlePrint() {
            var title = document.title
            document.title = `GRR-${this.form.code}-${this.form.warehouse.name}`
            await window.print();
            document.title = title
        },
        handleClose() {
            this.$router.push({
                name: `${this.$store.state.purchase.poReturn.baseRouterName}.index`,
            });
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>

<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}
</style>
